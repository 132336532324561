.footer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 25px 0;
}

.footer .imgDiv {
  width: 120px;
}

.footer .contact h2 {
  font-size: 20px;
  font-weight: 600;
}

.footer .contact .contactDetails div {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.footer .contact .contactDetails div span {
  margin-left: 5px;
}

.footer .socialMedia .links {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}
.footer .socialMedia h2 {
  font-size: 20px;
  font-weight: 600;
}

.footer .socialMedia .links a {
  transition: color 0.2s;
}

.footer .socialMedia .links a:nth-child(1) {
  color: #5277c7;
}
.footer .socialMedia .links a:nth-child(1):hover {
  color: #4266b3;
}

.footer .socialMedia .links a:nth-child(2) {
  color: #ff0000;
}

.footer .socialMedia .links a:nth-child(2):hover {
  color: #cc0505;
}

.footer .socialMedia .links a:nth-child(3) {
  color: #c40785;
}

.footer .socialMedia .links a:nth-child(3):hover {
  color: #b60a7c;
}
.divider {
  width: 80%;
  height: 1px;
  background: white;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 25px;
}

@media screen and (max-width: 1100px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .footer .contact .contactDetails div {
    justify-content: center;
  }
}
